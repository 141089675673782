"use client";

import { alpha, createTheme } from "@mui/material/styles";
const {
  palette: defaultPalette
} = createTheme();

/**
 * Custom grey palette (with zinc!)
 *
 * @see figma Design System 2024 - Colors
 *
 * white & black: try not to use
 *
 */
const grey = {
  white: "#FFFFFF",
  50: "#F7F7F7",
  75: "#EDEDF0",
  100: "#E4E4E7",
  200: "#CCCCD1",
  300: "#B4B4BB",
  400: "#A1A1AA",
  500: "#848490",
  600: "#686873",
  700: "#4B4B53",
  800: "#35353B",
  900: "#242428",
  black: "#000000"
};
const bhc = {
  50: "#F8FCFF",
  75: "#E2F3FF",
  100: "#CDEAFF",
  200: "#B5DDFB",
  300: "#94BFDE",
  400: "#77A1C1",
  500: "#5C85A3",
  600: "#456B86",
  700: "#305169",
  800: "#1F394C",
  900: "#162936"
};
const cds = {
  50: "#EBF9FF",
  100: "#C8EFFF",
  200: "#A5E5FF",
  300: "#82DBFF",
  400: "#5FD1FF",
  500: "#3CC7FF",
  600: "#20A2D7",
  700: "#0A7FAE",
  800: "#005F85",
  900: "#00425C"
};
const cdsWarning = {
  50: "#FFEFEF",
  100: "#FFDCDB",
  200: "#FFBFBD",
  300: "#F99C9A",
  400: "#F4605D",
  500: "#E53935",
  600: "#C62828",
  700: "#9F0909",
  800: "#750F0F",
  900: "#560B0B"
};
const important = {
  50: "#FFFAEB",
  100: "#FFF3D1",
  200: "#FFEBB2",
  300: "#FFDD80",
  400: "#FFD14C"
};
const cms = "#F7542B";
const CDSPalette = {
  /**
   * Avoid using these colors directly
   */
  bhc,
  cds,
  cdsWarning,
  important,
  /**
   * Use these colors freely
   */
  grey,
  cms,
  badge: bhc[75],
  text: {
    default: grey.black,
    onDark: grey.white
  },
  link: {
    default: cds[700],
    black: grey.black,
    onLight: cds[800],
    onDark: cds[100]
  },
  button: {
    filled: {
      color: grey.white,
      background: cds[700],
      hover: cds[800]
    },
    outlined: {
      color: cds[900],
      background: "transparent",
      hover: alpha(cds[700], 0.1),
      border: cds[900]
    },
    outlinedOnDark: {
      color: grey.white,
      background: "transparent",
      hover: alpha(grey.white, 0.2),
      border: grey.white
    },
    text: {
      color: cds[900],
      background: "transparent",
      hover: alpha(cds[700], 0.1)
    },
    textOnDark: {
      color: grey.white,
      background: "transparent",
      hover: alpha(grey.white, 0.2)
    },
    important: {
      color: grey.black,
      background: important[300],
      hover: important[400]
    }
  },
  notes: {
    // Note 1
    questionnaire: {
      details: "#E1F5F8",
      summary: {
        hover: "#B2EBF2;",
        main: "#CCF2F8"
      }
    },
    // Note 2
    "follow-up-checklist": {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF;",
        main: "#DDEFFF"
      }
    },
    // Note 2
    "referral-checklist": {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF;",
        main: "#DDEFFF"
      }
    },
    // Note 3
    "national-guidelines": {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 3
    "realtime-guidelines": {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 4 (default)
    note: {
      details: "#FEF8E7",
      summary: {
        hover: "#FFEAA6;",
        main: "#FFF2CC"
      }
    },
    // Note 5
    "priorities-guide": {
      border: "#D1D8DB",
      details: "#ffffff00",
      summary: {
        hover: "#ffffff00;",
        main: "#ffffff"
      }
    },
    // Note 6
    guidelines: {
      details: "#E2ECF3",
      summary: {
        main: "#C5D9E7",
        hover: "#A8C6DC"
      }
    },
    // Note 7
    highlight: {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF",
        main: "#DDEFFF"
      }
    },
    // Note 8
    urgent: {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 9
    educational: {
      details: "#E8F3F0",
      summary: {
        hover: "#AAE1D7",
        main: "#CCEBE6"
      }
    },
    collapsible: {
      border: "#D1D8DB",
      details: "#ffffff00",
      summary: {
        main: "#ffffff00",
        hover: "#D1D8DB"
      }
    }
  }
};
export const palette = {
  ...defaultPalette,
  ...CDSPalette
};
const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      // right aside also breaks here
      lg: 1280,
      xl: 1920
    }
  },
  constants: {
    height: {
      footerMin: 128,
      header: 64,
      headerXSExtra: 56,
      breadcrumbs: 40,
      asideHeader: 46
    },
    width: {
      contentMax: 640,
      //NOTE: Roughly 80ch, we cannot use ch, becuase we swap fonts and they have different sizes
      leftAside: 320,
      rightAside: 320,
      galleryMin: 940
    }
  },
  zIndex: {
    tooltip: 1500,
    snackbar: 1400,
    modal: 1300,
    drawer: 1200,
    appBar: 1100,
    fab: 1050,
    mobileStepper: 1000
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.bhc[800]
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.cds[700]}`
          }
        }
      },
      defaultProps: {
        disableRipple: true
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none"
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          "&.Mui-expanded": {
            margin: 0
          },
          margin: 0
        },
        root: {
          "&.Mui-expanded": {
            minHeight: 0
          },
          "@media print": {
            background: "transparent !important"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "8px 16px",
          fontWeight: 700,
          borderRadius: "6px",
          // const border-radius
          textTransform: "none",
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.bhc[900]}`
          }
        }
      },
      variants: [{
        props: {
          variant: "filled"
        },
        style: {
          color: palette.button.filled.color,
          backgroundColor: palette.button.filled.background,
          "&:hover": {
            backgroundColor: palette.button.filled.hover
          }
        }
      }, {
        props: {
          variant: "outlined"
        },
        style: {
          color: palette.button.outlined.color,
          backgroundColor: palette.button.outlined.background,
          border: `2px solid ${palette.button.outlined.border}`,
          "&:hover": {
            backgroundColor: palette.button.outlined.hover
          }
        }
      }, {
        props: {
          variant: "outlinedOnDark"
        },
        style: {
          color: palette.button.outlinedOnDark.color,
          backgroundColor: palette.button.outlinedOnDark.background,
          border: `2px solid ${palette.button.outlinedOnDark.border}`,
          "&:hover": {
            backgroundColor: palette.button.outlinedOnDark.hover
          }
        }
      }, {
        props: {
          variant: "text"
        },
        style: {
          color: palette.button.text.color,
          backgroundColor: palette.button.text.background,
          "&:hover": {
            backgroundColor: palette.button.text.hover
          }
        }
      }, {
        props: {
          variant: "textOnDark"
        },
        style: {
          color: palette.button.textOnDark.color,
          backgroundColor: palette.button.textOnDark.background,
          "&:hover": {
            backgroundColor: palette.button.textOnDark.hover
          }
        }
      }, {
        props: {
          variant: "important"
        },
        style: {
          color: palette.button.important.color,
          backgroundColor: palette.button.important.background,
          "&:hover": {
            backgroundColor: palette.button.important.hover
          }
        }
      }]
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: palette.bhc[800],
          "&:hover": {
            backgroundColor: palette.bhc[900]
          }
        },
        secondary: {
          backgroundColor: palette.cds[700],
          "&:hover": {
            backgroundColor: palette.cds[800]
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: palette.bhc[800],
          "&.Mui-checked": {
            color: palette.bhc[800]
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: palette.bhc[900]
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          color: palette.bhc[800],
          "&.Mui-checked": {
            color: palette.bhc[800]
          }
        },
        colorSecondary: {
          color: palette.cds[700],
          "&.Mui-checked": {
            color: palette.cds[800]
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 10
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.cds[700]}`
          },
          color: palette.link.default,
          variants: [{
            props: {
              color: "black"
            },
            style: {
              color: palette.link.black
            }
          }, {
            props: {
              color: "onLight"
            },
            style: {
              color: palette.link.onLight
            }
          }, {
            props: {
              color: "onDark"
            },
            style: {
              color: palette.link.onDark
            }
          }]
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.cds[700]}`
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-track": {
            marginTop: "2px",
            backgroundColor: palette.background?.default
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey[500]
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          marginBottom: "16px"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: bhc[800]
          }
        }
      }
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontSize: 16,
    fontFamily: "Figtree, sans-serif",
    h1: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "2.3565rem",
      fontWeight: 700
    },
    h2: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1.6667rem",
      fontWeight: 700
    },
    h3: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1.2853rem",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.2853rem",
      fontWeight: 400
    },
    h5: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1rem",
      fontWeight: 700
    },
    h6: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1rem",
      fontWeight: 700
    },
    body1: {
      fontSize: "1rem"
    },
    body2: {
      fontSize: "0.9rem"
    },
    subtitle1: {
      fontFamily: "Figtree, sans-serif",
      fontWeight: 700
    },
    subtitle2: {
      fontFamily: "Figtree, sans-serif",
      fontWeight: 700
    },
    caption: {
      opacity: 0.85
    }
  }
});
export default theme;